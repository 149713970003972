import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-wrap" }
const _hoisted_2 = { class: "text-white p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["w-full text-center", [_ctx.classContainer]])
    }, [
      _createElementVNode("div", {
        ref: "slotRef",
        onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleTooltip && _ctx.toggleTooltip(...args))),
        onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleTooltip && _ctx.toggleTooltip(...args)))
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 544),
      _createElementVNode("div", {
        ref: "tooltipRef",
        class: _normalizeClass([{ hidden: !_ctx.tooltipShow, block: _ctx.tooltipShow }, "bg-gray-500 border-0 block z-50 font-normal leading-normal text-xs max-w-xs text-left no-underline break-words shadow-lg rounded-md"])
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1)
      ], 2)
    ], 2)
  ]))
}