
import { defineComponent, ref } from 'vue';
import { createPopper, Placement } from '@popperjs/core';

export default defineComponent({
  name: 'Tooltip',
  props: {
    title: {
      type: String,
      required: true,
    },
    classContainer: {
      type: String,
      required: false,
      default: '',
    },
    position: {
      type: String,
      required: false,
      default: 'top',
    },
  },
  setup(props) {
    const tooltipShow = ref(false);
    const slotRef = ref<unknown>();
    const tooltipRef = ref<unknown>();

    const toggleTooltip = (): void => {
      if (tooltipShow.value) {
        tooltipShow.value = false;
      } else {
        tooltipShow.value = true;
        createPopper(
          slotRef.value as HTMLButtonElement,
          tooltipRef.value as HTMLDivElement,
          {
            placement: props.position as Placement,
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: () => [0, 5],
                },
              },
            ],
          }
        );
      }
    };

    return {
      slotRef,
      tooltipRef,
      tooltipShow,
      toggleTooltip,
    };
  },
});
